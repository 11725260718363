import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import {
  Article,
  ArticleHeader,
  ArticleDate,
  ArticleSection,
} from "../styledComponents/article"

import LayoutNoStripe from "../components/layoutNoStripe"
import SEO from "../components/seo"

const PrivacyPolicy = ({ data, location }) => {
  const pageTitle = data.wpPage.title
  const pageContent = data.wpPage.content

  return (
    <LayoutNoStripe displayNavBorder location={location}>
      <SEO 
        title={data.wpPage.title} 
        description="The cookie poilicy for the website of karinagrant.co.uk"
        url="/cookie-policy"
      />
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader>
          <h1 itemProp="headline">{pageTitle}</h1>
          <ArticleDate dateTime={data.wpPage.dateGmt}>{data.wpPage.date}</ArticleDate>
        </ArticleHeader>

        {!!pageContent && (
          <ArticleSection itemProp="articleBody">
            {parse(pageContent)}
          </ArticleSection>
        )}
      </Article>
    </LayoutNoStripe>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "cookie-policy" }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      dateGmt
    }
  }
`
